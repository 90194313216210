import * as React from "react"
import Layout from "../components/layout"

const DemoPage = () => (
  <Layout>
    <div className="text-center space-y-12 mt-32  h-screen">
      <iframe src='https://outlook.office365.com/owa/calendar/Gdaa24c1419ec42e0b9e7f081b5e9526e@NETORGFT5083948.onmicrosoft.com/bookings/' title={"Demo"} width={"100%"} height={"100%"} scrolling={"yes"}>
      </iframe>
    </div>
  </Layout>
)

export default DemoPage
